<template>
  <form v-bind="rootAttrs" @submit.prevent="submit">
    <fieldset :disabled="pending" :class="$cn('space-y-4', classAttr)">
      <slot />
      <Button
        v-if="!autosave && !hideSubmit"
        type="submit"
        :full-width="buttonFullWidth"
        :color="buttonColor"
        :shape="buttonShape"
        :variant="buttonVariant"
        class="disabled:cursor-wait disabled:opacity-75"
      >
        {{ pending ? pendingLabel : buttonLabel }}
      </Button>
    </fieldset>
  </form>
</template>

<script setup lang="ts">
import { FetchError } from "ofetch";

export type FormMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export type FormError = Record<string, string> | null;

export type ProvideForm = {
  autosave: boolean;
  pending: Ref<boolean>;
  getFieldErrors(name: string): ComputedRef<string | null>;
  submit(): Promise<void>;
};

export type FormProps = {
  data: Record<string, any>;
  endpoint: string;
  method?: FormMethod;
  buttonLabel?: string;
  pendingLabel?: string;
  buttonFullWidth?: boolean;
  buttonColor?: ButtonColor;
  buttonShape?: ButtonShape;
  buttonVariant?: ButtonVariant;
  hideErrors?: boolean;
  hideSubmit?: boolean;
  autosave?: boolean;
};

const props = withDefaults(defineProps<FormProps>(), {
  method: "POST",
  buttonLabel: "Submit",
  pendingLabel: "Submitting...",
  hideErrors: false,
  hideSubmit: false,
  autosave: false,
});

const pending = ref(false);
const errors = ref<FormError>(null);

const emit = defineEmits(["pending", "success", "error"]);

const attrs = useAttrs();
const classAttr = computed(() => attrs.class);
const rootAttrs = computed(() => {
  const { class: _, ...otherAttrs } = attrs;
  return otherAttrs;
});

const submit = async () => {
  try {
    // Reset errors
    errors.value = null;
    emit("error", errors.value);

    // Set pending
    pending.value = true;
    emit("pending", pending.value);

    // Perform request
    const response = await $larafetch(props.endpoint, {
      method: props.method,
      body: props.data,
    });

    // Emit response
    emit("success", response);
  } catch (err) {
    if (!(err instanceof FetchError)) throw err;
    if (err.response?.status !== 422) throw err;

    // Emit errors
    errors.value = err.data?.errors || null;
    emit("error", errors.value);
  } finally {
    // Set pending
    pending.value = false;
    emit("pending", pending.value);
  }
};

const getFieldErrors = (name: string) => computed(() => errors.value && errors.value[name] && errors.value[name][0]);

provide<ProvideForm>("form", {
  autosave: props.autosave,
  pending,
  getFieldErrors,
  submit,
});

defineExpose({
  submit,
});
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
